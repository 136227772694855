
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import ExportWidget from '../../components/shared/ExportWidget.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import OrderServiceApi from '../../api/http/OrderServiceApi';
import { AxiosResponse } from 'axios';
import { toUrl } from '@/util/helper';
import { OrderFlavor } from '@/enums/OrderFlovor';
import moment from 'moment';
import { PromoCodeApiService } from '@/api/http/PromoCodeApiService';
import { PromoCodeTeedeliApiService } from '@/api/http/PromoCodeTeedeliApiService';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';

const app = namespace('app');

interface ExportFormData {
  venue?: string;
  flavor?: OrderFlavor;
  from: string;
  to: string;
}

@Component({
  components: { ExportWidget, VWrapper },
})
export default class ExportPromoCode extends mixins(Notification) {
  @app.State('venues') public venues!: Venue[];

  public exportPromoCode(data: ExportFormData) {
    this.$startLoading('promo.csv');
    const api: OrderServiceApi = new OrderServiceApi();
    api
      .promoCodeOrdersCsv(data)
      .then((res: AxiosResponse) => this.notifySuccess('notification.successfulDownload'))
      .finally(() => {
        this.$stopLoading('promo.csv');
      });
  }

  public exportPromoCodes() {
    this.$startLoading('promo.overviewCsv');
    const api: PromoCodeApiService = new PromoCodeApiService();
    api
      .promoCodeCsv(this.$i18n.locale)
      .then((res: AxiosResponse) => toUrl(res.data, `promocodes-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('promo.overviewCsv');
      });
  }

  public exportPromoCodeCount() {
    this.$startLoading('promo.countCsv');
    const api: PromoCodeApiService = new PromoCodeApiService();
    api
      .promoCodeCountCsv(this.$i18n.locale)
      .then((res: AxiosResponse) => toUrl(res.data, `promocode-disabled-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('promo.countCsv');
      });
  }

  public exportPromoCodeSummary(data: ExportFormData) {
    this.$startLoading('promo.summaryCsv');
    const api: OrderServiceApi = new OrderServiceApi();
    api
      .promoCodeSummaryCsv(data)
      .then((res: AxiosResponse) => toUrl(res.data, `promocode-summary-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('promo.summaryCsv');
      });
  }

  public exportTeedeliPromoCode(data: ExportFormData) {
    this.$startLoading('promo.zip');
    const api: PromoCodeTeedeliApiService = new PromoCodeTeedeliApiService();
    api
      .promoCodeTeedeliZip(data)
      .then((res: AxiosResponse) => toUrl(res.data, `promocode-teedeli-${moment().format('DD-MM-YYYY')}.zip`))
      .finally(() => {
        this.$stopLoading('promo.zip');
      });
  }
}
