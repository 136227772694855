
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import ExportWidget from '../../components/shared/ExportWidget.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import OrderServiceApi from '../../api/http/OrderServiceApi';
import { AxiosResponse } from 'axios';
import { toUrl } from '@/util/helper';
import { OrderFlavor } from '@/enums/OrderFlovor';
import moment from 'moment';
import { CouponApiService } from '@/api/http/CouponApiService';

const app = namespace('app');

interface ExportFormData {
  venue?: string;
  flavor?: OrderFlavor;
  from: string;
  to: string;
}

@Component({
  components: { ExportWidget, VWrapper },
})
export default class ExportCoupon extends Vue {
  @app.State('venues') public venues!: Venue[];

  public exportCoupon(data: ExportFormData) {
    this.$startLoading('coupon.csv');
    const api: OrderServiceApi = new OrderServiceApi();
    api
      .couponsCsv(data)
      .then((res: AxiosResponse) => toUrl(res.data, `coupon-orders-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('coupon.csv');
      });
  }

  public exportCoupons() {
    this.$startLoading('coupon.overviewCsv');
    const api: CouponApiService = new CouponApiService();
    api
      .couponCsv(this.$i18n.locale)
      .then((res: AxiosResponse) => toUrl(res.data, `coupons-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('coupon.overviewCsv');
      });
  }

  public exportCouponCount() {
    this.$startLoading('coupon.countCsv');
    const api: CouponApiService = new CouponApiService();
    api
      .couponCountCsv(this.$i18n.locale)
      .then((res: AxiosResponse) => toUrl(res.data, `coupon-disabled-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('coupon.countCsv');
      });
  }

  public exportCouponsSummary(data: ExportFormData) {
    this.$startLoading('coupon.summaryCsv');
    const api: OrderServiceApi = new OrderServiceApi();
    api
      .couponSummaryCsv(data)
      .then((res: AxiosResponse) => toUrl(res.data, `coupon-summary-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('coupon.summaryCsv');
      });
  }
}
